@import "@syncfusion/ej2-react-pivotview/styles/material.css";

.e-pivotview {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 400px) {
    #pivot-table-section {
        overflow: auto;
    }
}

#PivotViewcontainerwrapper {
    height: auto !important;
}

.e-pivotview .e-hyperlinkcell {
    text-decoration: auto;
}

.e-pivotview .e-hyperlinkcell:hover {
    text-decoration: underline;
}

.e-pivotview .university-logo {
    border-radius: 50%;
    margin-right: 5px;
}

.urllink {
    float: right;
    margin-right: 10px;
    margin-top: 10px;
    font-size: 12px;
}

.e-pivotview .e-pivot-pager {

    opacity: 30;
    border:none !important;
}

.e-pivotfieldlist-container {
    max-width: 900px !important;
    top: 12% !important;
}