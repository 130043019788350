@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-layouts/styles/material.css";

:root {
  --primary-color: #004071;
  --secondary-color: #3c3c3c;
}

* {
  font-family: "Poppins", sans-serif;
}

body {
  @apply bg-white m-0
}


@media (max-width: 1100px) { /* Tailwind's `md` breakpoint is 768px, `lg` is 1024px */
  .gradient {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container {
    max-width: 360px;
  }
}

.offscreen {
  display: none;
}

.spinner {
  border: 0.3rem solid #f3f3f3; /* Light gray */
  border-top: 0.3rem solid var(--primary-color); /* Blue */
  border-radius: 50%;
  width: 1.6rem; /* 50px */
  height: 1.6rem; /* 50px */
  animation: spin 1s linear infinite;
  margin-bottom: 0.625rem; /* 10px */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add these styles to your CSS file or a <style> block */
.sidebar-container {
  overflow-y: scroll; 
  overflow-x: hidden; 
}

.sidebar-container::-webkit-scrollbar {
  width: 0px; 
}

/* .sidebar-container::-webkit-scrollbar-thumb {
  background-color: #c4c4c4; 
  border-radius: 8px;
}

.sidebar-container::-webkit-scrollbar-thumb:hover {
  background-color: #a3a3a3; 
}

.sidebar-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
} */

.e-dashboardlayout, .e-panel {
  z-index: auto !important;
}

.e-panel {
  border-radius: 10px !important;
  overflow-y: auto;
}

/* Customize the scrollbar width */
.notif::-webkit-scrollbar, .e-panel::-webkit-scrollbar {
  width: 5px; /* Set a thin scrollbar width */
  @apply py-2;
}

/* Track (the background of the scrollbar) */
.notif::-webkit-scrollbar-track, .e-panel::-webkit-scrollbar-track {
  background: transparent; /* Light background color for the track */
  border-radius: 10px; /* Match container border radius */
}

/* Handle (the draggable part of the scrollbar) */
.notif::-webkit-scrollbar-thumb, .e-panel::-webkit-scrollbar-thumb {
  background: #c5c5c5; /* Neutral color for the handle */
  border-radius: 10px; /* Round handle */
  border: 1px solid #f0f0f0; /* Space around the handle */
}

/* Handle on hover */
.notif::-webkit-scrollbar-thumb:hover, .e-panel::-webkit-scrollbar-thumb:hover {
  background: #999999; /* Darker color for hover state */
}

/* Handle on active state */
.notif::-webkit-scrollbar-thumb:active, .e-panel::-webkit-scrollbar-thumb:active {
  background: #777777; /* Even darker for active state */
}

.e-chart {
  height: 470px !important;
}

.chart-container {
  width: 100%;
  max-width: 1200px;
  margin: auto;

}

.responsive-chart {
  width: 100%;
  height: 100%;
}

#chartsAxisInsideCollection {
  max-height: 200px !important;
}

#chartsAxisLabels1 {
  max-height: 200px !important;
}
#chartsAxisLabels1 text {
  font-size: 12px;
}

#chartsAxisLabels0 text {
  font-size: 12px;
}

#charts_chart_legend_translate_g text {
  font-size: 12px;
}

.text-responsive {
  font-size: clamp(8px, 1vw, 18px);
}















