.input {
  @apply text-primary text-sm absolute rounded-md border border-gray-300 bg-transparent transition ease-in-out duration-100 w-full p-2 mt-4 focus:outline-none focus:border-primary;
}

.input:focus + .label,
.input:valid + .label,
.input:-webkit-autofill + .label  {
  @apply text-primary h-8;
  line-height: 30px;
  transform: translate(-13px, -36px) scale(0.9);
}

.e-otpinput.e-outline .e-otp-input-field {
  @apply !border-2
}

.e-otpinput.e-outline .e-otp-input-field:focus {
  @apply !border-primary
}