.profile-dropdown {
    width: 14rem !important;
}

.ant-dropdown-menu, .ant-btn {
    @apply rounded-md hover:!border-primary hover:!text-primary;  
}

.ant-dropdown-menu-item {
    font-size: 16px !important;
    padding-right: 4px !important;
    padding-left: 4px !important;
}

.site-item-collapsed {
    padding: 0 !important;
}