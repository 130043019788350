/* Custom styles for the checkbox */
.custom-checkbox {
    @apply w-[14px] h-[14px] appearance-none bg-white border border-primary rounded-sm inline-block relative cursor-pointer transition duration-200 ease-in-out;
  }
  
  .custom-checkbox:checked {
    @apply bg-primary border-primary;
  }
  
  .custom-checkbox:checked::after {
    content: '✔'; /* This is required for the checkmark */
    @apply text-[10px] text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
  }
  